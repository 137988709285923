<!--
 * @Author: your name
 * @Date: 2020-08-27 14:43:37
 * @LastEditTime: 2020-09-05 11:25:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\EBCHome\statisticalAnalysis\supplyDetailsPop.vue
-->
<template>
    <div class="supplyDetailsPop">
        <h4>
            <p>供货明细</p>
            <div class="light-beam"></div>
        </h4>
        <div class="select-con">
            <span>原材料规格：</span>
            <el-select
                v-model="value"
                placeholder="请选择"
                @change="selectSpaceCode"
            >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                height="100%"
                border
                style="width: 100%"
            >
                <el-table-column
                    prop="type_name"
                    label="物料名称"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="spec_name"
                    label="规格"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="tenant_name"
                    label="供应企业"
                >
                </el-table-column>
                <el-table-column
                    prop="supplied_count"
                    label="供应总量(吨)"
                >
                </el-table-column>
                <!-- <el-table-column
                    prop="clum5"
                    label="当前采购单价(元/吨)"
                    width="200"
                >
                </el-table-column> -->
                <el-table-column
                    prop="station_name"
                    label="场站"
                >
                </el-table-column>
                <el-table-column
                    label="开始供应时间"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.start_supply_date|substrTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="最近供应时间"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.end_supply_date|substrTime }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    label="备注"
                >
                    <template>
                        <span class="view">查看</span>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            // 货源地id
            sourceId: '',
            // 原材料编号
            typeCode: '',
            // 开始时间
            beginTime: '',
            // 结束时间
            endTime: '',
            // 规格型号
            specCode: '',
            options: [{
                value: '',
                label: '全部',
            } ],
            value: '',
            tableData: [],
        };
    },
    watch: {},
    computed: {},
    filters: {
        substrTime(val) {
            let str = '';
            if (val) {
                str = val.substr(0, 10);
            }
            return str;
        },
    },
    created() {},
    mounted() {
        this.sourceId = this.extr.sourceId;
        this.typeCode = this.extr.typeCode;
        this.beginTime = this.extr.beginTime;
        this.endTime = this.extr.endTime;
        this.handleTypeCodes();
        this.handleCompanyStat();
    },
    methods: {
        // 规格型号筛选
        selectSpaceCode(val) {
            this.specCode = val;
            this.handleCompanyStat();
        },
        // 处理规格型号数据
        async handleTypeCodes() {
            const res = await this.getTypeCodes();
            if (res) {
                res.map(item => {
                    this.options.push(
                        {
                            value: item.code,
                            label: item.name,
                        },
                    );
                });
            }
        },
        // 处理供货明细列表
        async handleCompanyStat() {
            const res = await this.getCompanyStat();
            if (res) {
                this.tableData = res;
            }
        },
        // 获取规格型号
        getTypeCodes() {
            const path = '/interfaceApi/procurement/material/group/type/spec/get/type_code?type_code=' + this.typeCode;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取供货明细列表
        getCompanyStat() {
            const path = '/interfaceApi/procurement/source/get_company_stat?beginTime='
            + this.beginTime + '&endTime=' + this.endTime + '&typeCode=' + this.typeCode
            + '&sourceId=' + this.sourceId + '&specCode=' + this.specCode;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>
<style lang="stylus">
.supplyDetailsPop
    height 100%
    background #032041
    box-shadow 1px 1px 6px 0px rgba(0,0,0,0.8)
    padding .2rem
    font-size .16rem
    h4
        font-size .18rem
        color #fff
        line-height .27rem
        background linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(108,151,216,1) 100%)
        -webkit-background-clip text
        -webkit-text-fill-color transparent
        text-align center
        position relative
        .light-beam
            position absolute
            top .1rem
            width 100%
            background url(./../images/line.png) no-repeat
            height .47rem
            background-size 100% 100%
    .select-con
        color #fff
        margin-top .3rem
        .el-input__inner
            background-color #0C274F
            border 1px solid #32579F
            color #fff
    .table-con
        height calc(100% - 1rem)
        margin-top .1rem
        .el-table
            border 1px solid #000
            background-color #032041
            font-size .14rem
            th,tr
                background-color #032041
            td,th
                border-bottom 1px solid #000
                border-right 1px solid #000
                text-align center
                padding .08rem 0
            th
                color #FFFFFF
                font-weight normal
            td
                color #20B0FB
                .view
                    color #C4FE72
                    cursor pointer
        .el-table--border
            td,th
                border-right 1px solid #000
            &::after
                background-color #000
            &::before
                background-color #000
        .el-table--enable-row-hover
            .el-table__body
                tr
                    &:hover
                        td
                            background #0D53A1
</style>
